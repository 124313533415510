import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/seo';

class Books extends React.Component {
    render() {
        const { data } = this.props;

        return (
            <Layout>
                <SEO
                    title="Objects"
                    theme="gray"
                    keywords={[`shopping list`, `buy for life`, `physical things`]}
                />
                <header>
                    <h1>Objects</h1>
                    <p>
                        My mother likes to tell me we're not a family of "things." Nevertheless,
                        here's a list of my favourites:
                    </p>
                </header>
                <article>
                    <ul className="objects-list">
                        <li>
                            <div className="row">
                                <Img
                                    title="TODO"
                                    imgStyle={{ objectFit: 'contain' }}
                                    fluid={data.nomosFront.childImageSharp.fluid}
                                />
                                <Img title="TODO" fluid={data.nomosBack.childImageSharp.fluid} />
                            </div>
                            <p className="caption">
                                <strong>Nomos Glasshütte Metro Date Power Reserve</strong>A watch
                                with a heartbeat. Runs for about 2 days. Winding it has become
                                something of a happy ritual for me.
                            </p>
                        </li>
                        <li>
                            <Img title="TODO" fluid={data.haitiBoots.childImageSharp.fluid} />
                            <p className="caption">
                                <strong>Paul Smith Haiti Leather&nbsp;Boots</strong>
                                These were murder to break in. Once through the crucible, however,
                                they'll last you a lifetime.
                            </p>
                        </li>
                        <li>
                            <Img title="TODO" fluid={data.ingridStarnes.childImageSharp.fluid} />
                            <p className="caption">
                                <strong>Ingrid Starnes Vetyver Bergamot&nbsp;Perfume</strong> So
                                much love went into developing this. Vic wore it when we first
                                started dating. Nothing will ever&nbsp;compare.
                            </p>
                        </li>
                        <li>
                            <Img
                                title="TODO"
                                style={{
                                    height: '80vw',
                                    minHeight: '500px',
                                    maxHeight: '720px',
                                }}
                                imgStyle={{
                                    height: '80vw',
                                    minHeight: '500px',
                                    maxHeight: '720px',
                                    objectFit: 'contain',
                                }}
                                fixed={data.wusthof.childImageSharp.fixed}
                            />
                            <p className="caption">
                                <strong>Wüsthof 4582 16cm Cook's&nbsp;Knife</strong>
                                We bought this 6 years ago when we were living in Germany and it's
                                the only knife we've ever needed.
                                <br />
                                <br />
                                It still feels brand new.
                            </p>
                        </li>
                        {/*<li>
              <Img title="TODO" fluid={data.honey.childImageSharp.fluid} />
              <p className="caption">
                <strong>Waiheke Manuka&nbsp;Honey</strong>
                The best honey in the world, collected by Rick and his family on
                sunny Waiheke Island. Soothes a sore throat, even better on a
                buttery bit of toast. This is my go-to gift.
              </p>
            </li>*/}
                    </ul>
                </article>
                <article>
                    <p>
                        <Link to="/">
                            <span className="arrow">←</span>&nbsp;&nbsp;Back to the rest of the
                            website.
                        </Link>
                    </p>
                </article>
            </Layout>
        );
    }
}

export default Books;

export const pageQuery = graphql`
    query NomosFront {
        nomosFront: file(relativePath: { eq: "nomos-front.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 255) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        nomosBack: file(relativePath: { eq: "nomos-back.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 255) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        haitiBoots: file(relativePath: { eq: "paul-smith-haiti-boots.jpeg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 585) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        ingridStarnes: file(relativePath: { eq: "ingrid-starnes.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 585) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        wusthof: file(relativePath: { eq: "wusthof.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fixed(width: 500) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        honey: file(relativePath: { eq: "waiheke-honey-collection.jpg" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 585) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
